module.exports = [{
      plugin: require('../node_modules/@wardpeet/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W8BZJJD","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          /* if the url contains \"builder.preview\" is being rendered inside of an iframe (Editor mode) */\n          const editorMode = typeof window !== 'undefined' && window.location.href.indexOf('builder.preview') > -1;\n          return {\n            disable_GTM: editorMode,\n            platform: 'gatsby',\n            isSpa: true\n          };\n        }"},"routeChangeEventName":"pageview","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
